import { useEffect, useState } from 'react';

export const useGetScreenHeight = () => {
  const isBrowser = typeof window !== 'undefined';
  const [screenHeight, setScreenHeight] = useState(900);
  useEffect(() => {
    if (!isBrowser) return;
    setScreenHeight(window.innerHeight);
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return screenHeight;
};
