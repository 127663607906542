import { motion } from 'framer-motion';
import { graphql, PageProps } from 'gatsby';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Icon } from '../shared/components/icon/Icon';
import { Layout } from '../shared/components/layout/Layout';
import { useGetScreenHeight } from '../shared/hooks/useGetScreenHeight';
import { useIsMobileDisplay } from '../shared/hooks/useIsMobileDisplay';
import { useOnInactivity } from '../shared/hooks/useOnInactivity';
import { useOnVerticalSwipe } from '../shared/hooks/useOnVerticalSwipe';
import { classnames } from '../shared/utils/classnames';

interface QueryResult {
  videos: {
    frontmatter: {
      realizationVideos: {
        title: string;
        videoUrl: string;
        posterUrl: string;
      }[]
    };
  };
}

const secondsToTimeline = (seconds?: number) => {
  if (!seconds) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};

const VideoControls: React.FC<{
  title: string;
  onPlayPauseClick: () => void;
  isPaused: boolean;
  onTimelineInput: (e: any) => void;
  onMuteUnmute: () => void;
  isMuted: boolean;
  currentTime: string;
  totalTime: string;
  timeInVideo: number;
  isPlayerVisible: boolean;
}> = ({
  title,
  isPaused,
  onPlayPauseClick,
  onTimelineInput,
  onMuteUnmute,
  isMuted,
  currentTime,
  totalTime,
  timeInVideo,
  isPlayerVisible,
}) => {
    return (
      <motion.div
        animate={{ opacity: isPlayerVisible ? 1 : 0 }}
        className="relative flex flex-row justify-center items-center z-10 mb-1.5 w-full text-white mt-auto"
      >
        <p className="text-body font-black absolute left-4 laptop-only">
          {title}
        </p>

        <div className="flex flex-row items-center justify-between md:justify-center mx-auto w-95% md:w-timeline md:max-w-timeline">
          <button onClick={onPlayPauseClick} className="mr-0.5">
            <Icon name={isPaused ? 'play' : 'pause'} alt="Play / pause video" />
          </button>
          <input
            type="range"
            min="0"
            max="100"
            onInput={onTimelineInput}
            value={timeInVideo || 0}
          />
          <button onClick={onMuteUnmute} className="ml-0.5">
            <Icon name={isMuted ? 'muted' : 'volume'} alt="Play / pause video" />
          </button>
          <p className="ml-1">
            {currentTime} / {totalTime}
          </p>
        </div>
      </motion.div>
    );
  };

const Realizations: React.FC<PageProps<QueryResult>> = ({ data }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videos = data.videos.frontmatter.realizationVideos.map((video) => ({
    title: video.title,
    url: video.videoUrl,
    poster: video.posterUrl,
    ref: useRef<HTMLVideoElement>(null)
  }))

  const [currentTime, setCurrentTime] = useState('00:00');
  const totalTime = secondsToTimeline(
    videos[currentVideoIndex].ref.current?.duration,
  );

  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [timeInVideo, setTimeInVideo] = useState(0);

  const onPlayPauseClick = () => {
    const video = videos[currentVideoIndex].ref.current;
    if (!video) return;
    if (video.paused || video.ended) {
      video.play();
      setIsPaused(false);
    } else {
      setIsPaused(true);
      video.pause();
    }
  };

  const onMuteUnmute = () => {
    const video = videos[currentVideoIndex].ref.current;
    if (!video) return;
    video.muted = !video.muted;
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const video = videos[currentVideoIndex].ref.current;
    if (!video) return;

    video.addEventListener('ended', () => {
      setIsPaused(true);
    });

    video.addEventListener('timeupdate', function () {
      setCurrentTime(secondsToTimeline(video.currentTime));
      setTimeInVideo((video.currentTime * 100) / video.duration);
    });
  }, [currentVideoIndex]);

  const onTimelineInput = (e: any) => {
    const video = videos[currentVideoIndex].ref.current;
    if (!video) return;
    video.currentTime = (parseFloat(e.target.value) / 100) * video.duration;
  };

  const onVideoChanged = (newVideoIndex: number) => {
    if (newVideoIndex === currentVideoIndex) return;
    videos[currentVideoIndex].ref.current?.pause();

    setCurrentVideoIndex(newVideoIndex);
    const video = videos[currentVideoIndex].ref.current;
    if (!video) return;
    setTimeInVideo(0);
    setIsPaused(true);
  };

  const screenHeight = useGetScreenHeight();
  const coolDown = 1000;
  let lastVideoChange = new Date().getTime();
  if (typeof window !== 'undefined') {
    window.addEventListener('wheel', (event) => {
      const now = new Date().getTime();
      if (now - lastVideoChange < coolDown) return;
      lastVideoChange = now;
      if (event.deltaY > 0 && currentVideoIndex !== videos.length - 1) {
        onVideoChanged(Math.min(currentVideoIndex + 1, videos.length - 1));
      } else if (event.deltaY < 0 && currentVideoIndex !== 0) {
        onVideoChanged(Math.max(currentVideoIndex - 1, 0));
      }
    });
  }

  const [isPlayerVisible, setIsPlayerVisible] = useState(true);

  useOnInactivity({
    onActive: () => { setIsPlayerVisible(true) },
    onInactive: () => { if (!isPaused) { setIsPlayerVisible(false) } },
    delay: 3000,
  });

  useOnVerticalSwipe({
    onSwipeDown: () => {
      console.log('SWIPE DOWN');
      onVideoChanged(Math.max(currentVideoIndex - 1, 0));
    },
    onSwipeUp: () => {
      console.log('SWIPE UP');
      onVideoChanged(Math.min(currentVideoIndex + 1, videos.length - 1));
    },
  });

  const isMobileDisplay = useIsMobileDisplay();

  return (
    <Layout isPlayerVisible={isPlayerVisible}>
      <div className="fixed right-2 flex flex-col top-1/2 z-10 transform -translate-y-1/2">
        {videos.map((video, index) => (
          <button
            key={`button${index}`}
            className={classnames({
              'p-1 rounded-full mb-1 focus:outline-none': true,
              'bg-gray-videoPicker': index !== currentVideoIndex,
              'bg-white': index === currentVideoIndex,
            })}
            onClick={() => onVideoChanged(index)}
          ></button>
        ))}
      </div>

      {videos.map((video, index) => {
        return (
          <motion.video
            initial={{ top: screenHeight * (index - currentVideoIndex) }}
            transition={{ duration: 0.75 }}
            animate={{
              top: screenHeight * (index - currentVideoIndex),
            }}
            preload="metadata"
            className={classnames({
              'fixed left-0 h-full w-full': true,
              'z-0': index !== currentVideoIndex,
              'z-5': index === currentVideoIndex,
              'object-cover': !isMobileDisplay,
            })}
            ref={video.ref}
            key={index}
            poster={video.poster}
          >
            <source src={video.url} type="video/mp4" />
          </motion.video>
        );
      })}

      <VideoControls
        title={videos[currentVideoIndex].title}
        onPlayPauseClick={onPlayPauseClick}
        isPaused={isPaused}
        onTimelineInput={onTimelineInput}
        onMuteUnmute={onMuteUnmute}
        isMuted={isMuted}
        currentTime={currentTime}
        totalTime={totalTime}
        timeInVideo={timeInVideo}
        isPlayerVisible={isPlayerVisible}
      />
    </Layout>
  );
};

export default Realizations;

export const pageQuery = graphql`
  query RealisationPageQuery {
    videos: markdownRemark(
      fileAbsolutePath: { regex: "/realizationVideos/realizationvideos.md/" }
    ) {
      frontmatter {
        realizationVideos {
          title
          videoUrl
          posterUrl
        }
      }
    }
  }
`;
