import { useEffect, useState } from 'react';

export const useOnVerticalSwipe = (params: {
  onSwipeUp: () => void;
  onSwipeDown: () => void;
}) => {
  const [yDown, setYDown] = useState(0);

  function touchstart(evt: any) {
    const firstTouch = (evt.touches || evt.originalEvent.touches)[0];
    setYDown(firstTouch.clientY);
  }

  function touchend(evt: any) {
    const yDiff = evt.changedTouches[0].pageY - yDown;

    if (Math.abs(yDiff) > 75) {
      if (yDiff < 0) params.onSwipeUp();
      else params.onSwipeDown();
    }

    setYDown(0);
  }

  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {
    if (!isBrowser) return;

    window.addEventListener('touchstart', touchstart);
    window.addEventListener('touchend', touchend);

    return () => {
      window.removeEventListener('touchstart', touchstart);
      window.removeEventListener('touchend', touchend);
    };
  });
};
