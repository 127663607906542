import { useEffect, useState } from 'react';

export const useOnInactivity = (params: {
  onActive: () => void;
  onInactive: () => void;
  delay: number;
}) => {
  const isBrowser = typeof window !== 'undefined';
  let [t, setT] = useState<any>(undefined);

  function resetTimer() {
    params.onActive();
    clearTimeout(t);
    setT(setTimeout(params.onInactive, params.delay)); // time is in milliseconds
  }

  useEffect(() => {
    if (!isBrowser) return;

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.addEventListener('wheel', resetTimer);

    return () => {
      window.onload = null;
      window.onmousemove = null;
      window.ontouchstart = null;
      window.onclick = null;
      window.removeEventListener('wheel', resetTimer);
    };
  });
};
