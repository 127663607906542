import { useEffect, useState } from 'react';

const MOBILE_BREAKPOINT = 768;

export const useIsMobileDisplay = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 900);

  useEffect(() => {
    if (!isBrowser) return;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width <= MOBILE_BREAKPOINT;
};
